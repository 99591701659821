<template>
  <n-theme
    type="driver"
    class="view-container"
  >
    <n-scrollable class="scroll-view">
      <n-nav-bar 
        class="top-nav"
        :title="$t('main.provide.tripOptions.title')" 
      />
      <n-layout>
        <trip-options
          v-model="options"
          class="span-6"
          :from="trip.from_street"
          :to="trip.to_street"
          :default-time="time"
          :with-seats="true"
          :seats="trip.seats"
          :is-driver="true"
        />
        <n-button
          :loading="creating"
          size="lg"
          block
          @click="create"
        >
          {{ $t('main.provide.tripOptions.create') }}
        </n-button>
      </n-layout>
    </n-scrollable>

    <offer-return-trip-dialog ref="offerReturnTripDialog" />
    <n-dialog />
  </n-theme>
</template>

<script>

import * as util from '@/vendor/utils';
import commuteApi from '@/api/commute';
import { EventBus } from '@/vendor/events';
import EventTypes from '@/vendor/event-types';
import TripOptions from '@/components/shared/tripOptions';
import OfferReturnTripDialog from '@/dialogs/offerReturnTripDialog';
import i18n from '@/i18n/index';
import { sentryException } from '@/vendor/sentry';
import { ignoreHandledErrors } from '@/vendor/axios';

export default {
  name: 'MainProvideCreateRecurringOptions',
  components: {
    TripOptions,
    OfferReturnTripDialog
  },
  beforeRouteEnter(to, from, next) {
    if (!to.params.trip) {
      next({ name: 'main.provide' });
      return;
    }

    next();
  },
  props: {
    trip: {
      type: Object,
      required: true,
    },
    time: { },
    from: {
      type: Object,
      required: false
    },
    to: {
      type: Object,
      required: false
    },
    return: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {
      creating: false,
      options: {
        seats: this.trip.seats,
        chargeForTrip: this.trip.charge_for_trip
      },
    }
  },
  methods: {
    async create() {
      if (this.creating) {
        return;
      }

      const recurringTripData = {
        ...this.trip,
        ...util.parseRecurringOptions(this.options),
        seats: this.options.seats,
      }

      this.creating = true;

      commuteApi.postDriverTrip(recurringTripData)
        .then(() => this.onSavedSuccessfully())
        .catch(ignoreHandledErrors)
        .catch(error => {
          if (error?.response?.status === 422) {
            sentryException(error);

            this.$error(i18n.t('main.provide.tripOptions.unprocessableError'));
            this.$router.resetToHome();
            return;
          }
          throw error;
        })
        .finally(() => {
            this.creating = false;
        });
    },
    onSavedSuccessfully() {
      EventBus.$emit('fetch-recurring-overview');

      if (this.return) {
        this.redirectToHome();
        return;
      }

      this.$refs.offerReturnTripDialog.show(
        // yes
        () => {
          const { from, to } = this;

          this.$router.resetToHome();
          this.$router.push({ name: 'main.provide', params: { from: to, to: from, return: true } });
        },

        // no
        () => {
          this.redirectToHome();
        }
      );
    },
    redirectToHome() {
      this.$router.resetToHome();
      this.$success(this.$t('main.provide.recurringTripCreated'));
      EventBus.$emit('trip-event', { 'action': EventTypes.TRIP_CREATED, 'type': 'Recurring' });
    },
  },
}
</script>

<style lang="scss" scoped>
.scroll-view {
  padding: 70px 0;
  
  @supports (-webkit-touch-callout: none) {
    padding-top: 100px;
  }

  .top-nav {
    position: fixed;
    top: 0;
    background-color: white;
    z-index: 1;
  }
}
</style>