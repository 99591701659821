import Vue from 'vue';
import Router from 'vue-router';
import { replaceRouterMethods, setRouterImplementationHooks } from '@/vendor/router-implementation';
import store from '@/store';
import { isBrowser } from '@/vendor/utils';

import Main from '@/screens/main';
import MainStart from '@/screens/main/start';
import MainProvide from '@/screens/main/provide';
import MainDemand from '@/screens/main/demand';
import MainActiveTrip from '@/screens/main/activeTrip';
import EditRequest from '@/screens/main/editRequest';
import EditTrip from '@/screens/main/editDriverTrip';

import Profile from '@/screens/profile';
import Notifications from '@/screens/notifications';
import ConversationsOverview from "@/screens/chat/conversationsOverview";
import UserConversation from "@/screens/chat/userConversation";
import Account from "@/screens/account/account";
import AccountTransactions from "@/screens/account/transactions";
import AccountPayout from "@/screens/account/payout";
import AccountTravelPass from "@/screens/account/travelPass";

import DriverTripDetails from '@/screens/tripDetails/driverTrip'
import PassengerTripDetails from '@/screens/tripDetails/passengerTrip'
import PassengerTripDetailsDriver from '@/screens/tripDetails/driverPassengerTrip'

import DemandOptions from "@/screens/main/demand/createDemandOptions";
import RecurringOptions from "@/screens/main/provide/createRecurringOptions";
import RecurringEdit from "@/screens//editRecurring";
import History from "@/screens/history";

import Vacation from "@/screens/vacation";
import LandingVoucher from "@/screens/landing/voucher";
import LandingBase from "@/screens/landing/base";
import LandingRejseplanen from "@/screens/landing/rejseplanen";

import Maintenance from "@/screens/maintenance";
import ContentNotFound from "@/screens/contentNotFound";
import SearchResult from "@/screens/tripDetails/searchResult";



const replaceRouterImplementation = !isBrowser();
if (replaceRouterImplementation) { replaceRouterMethods(Router); }

Vue.use(Router);

const router = new Router({
  mode: "history",
  routes: [
    {
      path: "/",
      component: Main,
      children: [
 
        {
          path: "/",
          name: "main.start",
          component: MainStart
        },
        {
          path: "provide",
          name: "main.provide",
          component: MainProvide,
          props: true,
        },
        {
          path: "demand",
          name: "main.demand",
          component: MainDemand,
          props: true,
        },
        {
          path: "active-trip",
          name: "main.active-trip",
          component: MainActiveTrip,
          props: true,
        },
        {
          path: "request/edit/:id?",
          name: "edit-request",
          component: EditRequest,
          props: true,
        },
        {
          path: "trip/edit/:id?",
          name: "edit-trip",
          component: EditTrip,
          props: true,
        },
      ]
    },
    {
      path: "/sign-up",
      component: () => import(/* webpackChunkName: "signup" */ "@/screens/auth"),
      children: [
        {
          path: "validate-otp",
          name: "sign-up.validate-otp",
          component: () => import(/* webpackChunkName: "signup" */ "@/screens/signUp/phoneOTP"),
          props: (route) => ({ ...route.query })
        }
      ]
    },
    {
      path: "/auth",
      component: () => import(/* webpackChunkName: "auth" */ "@/screens/auth"),
      children: [
        {
          path: "phone-login",
          name: "auth.phone-login",
          component: () => import(/* webpackChunkName: "auth" */ "@/screens/auth/phoneOTPLogin"),
          props: (route) => ({ ...route.query })
        },
      ]
    },
    {
      path: "/profile",
      name: "profile",
      component: Profile
    },
    {
      path: "/notifications",
      name: "notifications",
      component: Notifications
    },
    {
      /** old route from before NBG-4500 */
      name: 'messages.commute',
      path: "/messages",
      beforeEnter: async (to, from, next) => {
        next({name: 'conversations'})
      }
    },
    {
      path: "/conversations",
      name: 'conversations',
      component: ConversationsOverview,
    },
    {
      path: "/conversations/:userId",
      name: "userConversation",
      component: UserConversation,
      props: true
    },
    {
      path: "/account",
      name: "account",
      props: true,
      component: Account
    },
    {
      path: "/account/transactions",
      component: AccountTransactions
    },
    {
      path: "/account/payout",
      name: "account-payout",
      component: AccountPayout
    },
    {
      path: "/account/travel-pass",
      name: "travel-pass",
      component: AccountTravelPass
    },
    { // Search Result
      path: "/trip-details/search-result",
      name: "trip-details-search",
      component: SearchResult,
      props: true,
    },
    { // As passenger
      path: "/trip-details/passenger/:passengerTripId/:driverTripId",
      name: "trip-details-passenger",
      component: PassengerTripDetails,
      props: true,
    },
    { // As driver - showing a single passenger
      path: "/trip-details/driver/:driverTripId/passenger/:passengerTripId",
      name: "trip-details-driver-passenger",
      component: PassengerTripDetailsDriver,
      props: true,
    },
    { // As driver
      path: "/trip-details/driver/:driverTripId",
      name: "trip-details-driver",
      component: DriverTripDetails,
      props: true,
    },
    {
      path: "/trip-history",
      name: "trip-history",
      component: History,
    },
    {
      path: "/recurring/edit/:id?",
      name: "edit-recurring",
      component: RecurringEdit,
      props: true,
    },
    {
      path: "/provide/option",
      name: "provide.options",
      component: RecurringOptions,
      props: true,
    },
    {
      path: "/demand/options",
      name: "demand.options",
      component: DemandOptions,
      props: true,
    },
    {
      path: "/demand/options/return",
      name: "demand.options.return",
      component: DemandOptions,
      props: true,
    },
    {
      path: "/content-not-found",
      name: "content-not-found",
      component: ContentNotFound,
    },
    {
      path: "/maintenance",
      name: "maintenance",
      component: Maintenance,
    },
    {
      path: "/vacation",
      name: "vacation",
      component: Vacation
    },
    {
      path: '/landing',
      name: 'landing',
      component: LandingBase,
    },
    {
      path: '/voucher/:code',
      name: 'landing-voucher',
      component: LandingVoucher,
      props: true,
    },
    {
      path: '/redirect/trip',
      name: 'landing-rejseplanen',
      component: LandingRejseplanen,
    },
    {
      path: '/landing/login-as',
      name: 'landing-login-as',
      beforeEnter: async (to, from, next) => {
        await store.commit('user/CLEAR');

        const token = window.location.hash.substring(1);

        await store.commit('user/UPDATED_TOKEN', token);
        await store.dispatch('user/FETCH_PROFILE');

        next('/');
      }
    },
    {
      path: "",
      component: () => import(/* webpackChunkName: "book-ride" */ "@/screens/landing/bookRide"),
      children: [
        {
          path: "/book",
          meta: { fullscreen: false },
          name: "landing-book-ride.start",
          component: () => import(/* webpackChunkName: "book-ride" */ "@/screens/landing/bookRide/start")
        },
        {
          path: "/qr/:code",
          meta: { fullscreen: false },
          name: "landing-book-ride.qr",
          component: () => import(/* webpackChunkName: "book-ride" */ "@/screens/landing/bookRide/start")
        },
        {
          path: "/book/details",
          meta: { fullscreen: false },
          name: "landing-book-ride.details",
          component: () => import(/* webpackChunkName: "book-ride" */ "@/screens/landing/bookRide/details")
        },
        {
          path: "/book/login",
          meta: { fullscreen: false },
          name: "landing-book-ride.login",
          component: () => import(/* webpackChunkName: "book-ride" */ "@/screens/landing/bookRide/authPhone")
        },
        {
          path: "/book/otp",
          meta: { fullscreen: false },
          name: "landing-book-ride.otp",
          component: () => import(/* webpackChunkName: "book-ride" */ "@/screens/landing/bookRide/authOTP"),
          props: (route) => ({ ...route.query })
        },
      ]
    },
  ]
});

router.beforeEach((to, from, next) => {
  if (!isBrowser() || to.name?.includes('landing')) {
    return next();
  }

  return next('/book');
})

if (replaceRouterImplementation) {
    setRouterImplementationHooks(router);
}

/**
 * @param {UserNotification} notification
 */
export function navigateFromNotification(notification) {
    if (notification.route == "trip-details-search") {
      router.push({
        name: notification.route,
        query: notification.params,
      });
      return;
    }

    router.push({
      name: notification.route,
      params: notification.params,
    });

}

export default router;
