<template>
  <n-grid>
    <n-column :span="6">
      <div class="eligibility-container">
        <div class="eligibility-content">
          <n-icon
            name="info-large"
            color="transparent"
            outline
            custom-size="26"
          />
          <div class="text-content">
            <n-text
              size="md"
              weight="medium"
              color="blue-dark"
            >
              {{ $t('main.parkingEligibiltyInformation.title') }}
            </n-text>
            <n-text
              size="sm"
              color="blue-neutral"
            >
              {{ $t('main.parkingEligibiltyInformation.text', { parkingSiteName}) }}
            </n-text>
          </div>
        </div>
      </div>
    </n-column>
  </n-grid>
</template>

<script>
export default {
  name: 'ParkingEligibilityInformation',
  props: {
    parkingSiteName: {
      type: String,
      required: true,
    },
  }
}
</script>

<style lang="scss" scoped>
.eligibility-container {
  background-color: var(--color-blue-50);
  border-radius: 6px;
  padding: 20px;
  margin: 16px 0;

  .eligibility-content {
    display: flex;
    gap: 18px;
    align-items: center;

    .info-icon {
      padding: 8px;
    }

    .text-content {
      display: flex;
      flex-direction: column;
    }
  }
}
</style>