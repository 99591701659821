<template>
  <div class="parking-header" :class="{ 'parking-header-with-info': showInfo }">
    <div class="parking-header__text">
      <div class="title-container">
        <n-text preset="header-medium" color="blue-neutral">
          {{ $t('main.parkingSection.title') }}
        </n-text>
        <n-icon
          v-if="showInfo"
          name="info-large"
          @click="$emit('info-click')"
        />
      </div>
      <n-text
        v-if="licensePlate && displayType === 'overview'"
        preset="body"
        color="neutral"
        class="license-plate-text"
      >
        <template v-if="isExpired">
          {{ $t('main.parkingSection.subtitleExpired.part1') }}
          <strong>{{ licensePlate }}</strong>
          {{ $t('main.parkingSection.subtitleExpired.part2') }}
          <strong>{{ parkingSection }}, {{ parkingSpot }}</strong>
          {{ $t('main.parkingSection.subtitleExpired.part3') }}
        </template>
        <template v-else>
          {{ $t('main.parkingSection.subtitle') }}
          <strong>{{ licensePlate }}</strong>
        </template>
      </n-text>
    </div>
  </div>
</template>

<script>
export default {
  name: 'ParkingSectionHeader',
  props: {
    showInfo: {
      type: Boolean,
      default: false,
    },
    licensePlate: {
      type: String,
      default: '',
    },
    isOverview: {
      type: Boolean,
      default: false,
    },
    isExpired: {
      type: Boolean,
      default: false,
    },
    displayType: {
      type: String,
      default: 'overview',
      validator: (value) => ['overview', 'active-trip'].includes(value),
    },
    parkingSection: {
      type: String,
      default: '',
    },
    parkingSpot: {
      type: String,
      default: '',
    },
  },
  emits: ['info-click'],
};
</script>

<style lang="scss" scoped>
.parking-header {
  margin-bottom: 16px;

  &-with-info {
    margin-bottom: 24px;
  }

  &__text {
    display: flex;
    flex-direction: column;
    gap: 4px;
    width: 100%;
  }

  .title-container {
    display: flex;
    align-items: center;
    gap: 20px;
  }

  .license-plate-text {
    margin-top: 4px;
  }
}
</style>
