<template>
  <n-grid>
    <n-column :span="6">
      <template v-if="displayType === 'overview'">
        <div
          class="parking-overview"
          :class="{ 'parking-overview--expired': isExpired }"
        >
          <svg-icon
            v-if="isExpired"
            name="close"
            class="parking-overview__close-icon"
            color="grey"
            outline
            size="sm"
            @click="handleDismiss"
          />
          <parking-section-header
            :license-plate="licensePlate"
            :parking-section="parkingSection"
            :parking-spot="parkingSpot.name"
            :is-overview="true"
            :is-expired="isExpired"
            display-type="overview"
          />

          <div v-if="isExpired" class="parking-expired-container">
            <n-icon name="carparking" size="xxl" color="white" />
            <n-text color="orange" align="center" weight="medium">
              {{
                $t('main.parkingSection.reservationExpired', {
                  time: formatTime(reservation.endingAt),
                })
              }}
            </n-text>
          </div>
          <div v-else class="parking-details-overview">
            <div class="parking-details-overview__inner" :style="progressStyle">
              <div class="parking-details-overview__inner-content">
                <div class="parking-details-overview__inner-section-name">
                  <n-text size="sm" color="blue-darker" weight="regular">
                    {{ parkingSection }}
                  </n-text>
                </div>
                <div class="parking-details-overview__inner-spot-name">
                  {{ parkingSpot.name }}
                </div>
                <div class="parking-details-overview__inner-expiration-time">
                  <n-text size="md" color="grey-neutral" weight="medium">
                    {{ $t('main.parkingSection.reservationEndsIn') }}
                  </n-text>
                  <n-text size="md" color="orange" weight="medium">
                    {{ countdownTime }}
                  </n-text>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div v-if="!isExpired" class="parking-footer">
          <n-button
            block
            size="lg"
            type="outlined"
            color="blue"
            inverted
            :text-uppercase="false"
            :loading="isReleasing"
            @click="handleButtonClick"
          >
            {{ $t('main.parkingSection.releaseButton') }}
          </n-button>

          <n-seperator class="span-6" />
        </div>
      </template>
      <template v-else>
        <n-seperator class="span-6 seperator" />
        <parking-section-header
          :license-plate="licensePlate"
          :show-info="true"
          :is-overview="false"
          :is-expired="isExpired"
          @info-click="openInformationDialog"
          display-type="active-trip"
        />

        <template v-if="hasAvailableParkingSpots || reservation">
          <template v-if="reservation">
            <div class="parking-details">
              <div class="detail-section">
                <div class="detail-column">
                  <n-text color="blue-darker">
                    {{
                      $t('main.parkingSection.reservationPlacement', {
                        parkingSection,
                        parkingSpot: parkingSpot.name,
                        parkingSiteName,
                      })
                    }}
                  </n-text>
                  <div class="spot-number">
                    <n-text size="xxl" color="blue-neutral" class="spot-text">
                      {{ reservation.parkingSpot.name }}
                    </n-text>
                  </div>
                </div>
              </div>
            </div>
          </template>
          <div class="form-section">
            <n-input
              v-model="licensePlate"
              :placeholder="$t('main.parkingSection.licensePlatePlaceholder')"
              :icon="reservation && licensePlate ? 'pencil' : undefined"
              maxlength="8"
              @input="handleLicensePlateChange"
            />

            <n-button
              v-if="showSaveButton && reservation"
              block
              size="lg"
              color="default"
              :text-uppercase="false"
              @click="handleUpdateParkingReservation"
            >
              {{ $t('main.parkingSection.saveLicensePlate') }}
            </n-button>

            <n-button
              v-if="reservation"
              block
              size="lg"
              type="outlined"
              color="blue"
              inverted
              :loading="isReleasing"
              :text-uppercase="false"
              @click="onRelease"
            >
              {{ $t('main.parkingSection.releaseButton') }}
            </n-button>

            <n-button
              v-else
              block
              size="lg"
              type="filled"
              color="default"
              :loading="isBooking"
              :text-uppercase="false"
              @click="onBook"
            >
              {{ $t('main.parkingSection.reserveButton') }}
            </n-button>
          </div>
        </template>

        <template v-else>
          <div class="parking-details parking-details__no-available-spots">
            <n-icon name="sad-smiley" custom-size="72" color="" outline />

            <n-text size="lg" color="grey-dark" align="center">
              {{ $t('main.parkingSection.noAvailableParkingSpots') }}
            </n-text>
          </div>
        </template>
      </template>
    </n-column>
  </n-grid>
</template>

<script>
import { intervalToDuration } from 'date-fns';
import { format } from 'date-fns';
import commuteApi from '@/api/commute';
import ParkingSectionHeader from './parkingSectionHeader.vue';
import { namespacedTypes as namespacedCommute } from '@/store/modules/commute-types';
import { EventBus } from '@/vendor/events';

export default {
  name: 'ParkingSection',
  components: {
    ParkingSectionHeader,
  },
  props: {
    reservation: {
      type: Object,
      required: false,
      default: null,
    },
    displayType: {
      type: String,
      required: false,
      validator: (value) => ['overview', 'active-trip'].includes(value),
      default: 'overview',
    },
    hasAvailableParkingSpots: {
      type: Boolean,
      required: false,
      default: false,
    },
    parkingSiteName: {
      type: String,
      required: false,
      default: '',
    },
    partnerLogo: {
      type: String,
      required: false,
      default: null,
    },
    latestLicensePlate: {
      type: String,
      required: false,
      default: '',
    },
  },
  data() {
    return {
      parkingSpot: this.reservation?.parkingSpot || null,
      licensePlate: this.reservation?.licensePlate ?? this.latestLicensePlate,
      parkingSection: this.reservation?.section?.name || '',

      licensePlateEditable: false,

      isBooking: false,
      isReleasing: false,
      originalLicensePlate: '',
      showSaveButton: false,

      currentTime: new Date(),
      timer: null,
    };
  },
  computed: {
    countdownTime() {
      const duration = intervalToDuration({
        start: this.currentTime,
        end: new Date(this.reservation.endingAt),
      });

      // Format as HH:mm:ss
      const hours = String(duration.hours).padStart(2, '0');
      const minutes = String(duration.minutes).padStart(2, '0');
      const seconds = String(duration.seconds).padStart(2, '0');

      return `${hours}:${minutes}:${seconds}`;
    },
    isExpired() {
      return new Date(this.reservation?.endingAt) < this.currentTime;
    },
    progressPercentage() {
      const now = this.currentTime;
      const start = new Date(this.reservation.startingAt);
      const end = new Date(this.reservation.endingAt);

      const total = end - start;
      const elapsed = now - start;

      const value = Math.min(100, Math.max(0, (elapsed / total) * 100));

      return value;
    },
    progressStyle() {
      if (this.isExpired) {
        return {
          background:
            'conic-gradient(var(--color-red-700) 0%, var(--color-red-700) 100%)',
        };
      }
      return {
        background: `conic-gradient(
          var(--color-orange-50) ${this.progressPercentage}%,
          var(--color-orange) ${this.progressPercentage}% 100%
        )`,
      };
    },
  },
  watch: {
    reservation: {
      handler(newVal) {
        this.parkingSpot = newVal?.parkingSpot || null;
        this.licensePlate = newVal?.licensePlate || this.latestLicensePlate;
        this.originalLicensePlate = newVal?.licensePlate || '';
        this.parkingSection = newVal?.parkingSpot.section.name || '';
        this.isReleasing = false;
        this.isBooking = false;
        this.showSaveButton = false;
      },
      immediate: true,
    },
  },
  mounted() {
    this.timer = setInterval(() => {
      this.currentTime = new Date();
    }, 1000);
  },
  beforeDestroy() {
    if (this.timer) {
      clearInterval(this.timer);
    }
  },
  methods: {
    formatTime(date) {
      return format(new Date(date), 'HH:mm');
    },
    formatDate(date) {
      return format(new Date(date), 'EEEE, dd MMMM');
    },
    openInformationDialog() {
      this.$modal.show('active-trip-dialog', {
        headerPicture: this.partnerLogo,
        title: this.$t('main.parkingSection.informationDialog.title'),
        text: this.$t('main.parkingSection.informationDialog.text', {
          parkingSiteName: this.parkingSiteName,
        }),
        color: 'neutral',
        success: {
          hidden: true,
        },
        closeButton: true,
      });
    },
    onBook() {
      if (!this.licensePlate) {
        this.$error(this.$t('main.parkingSection.missingLicensePlate'));
        return;
      }

      this.isBooking = true;
      this.$emit('bookParkingSpot', {
        licensePlate: this.licensePlate,
      });
    },
    onRelease() {
      this.$modal.show('active-trip-dialog', {
        title: this.$t('main.parkingSection.releaseDialog.title'),
        text: this.$t('main.parkingSection.releaseDialog.text'),
        color: 'error',
        cancel: true,
        cancelButton: {
          text: this.$t('main.parkingSection.releaseDialog.cancelButton'),
          handler: this.handleCancel,
        },
        success: {
          text: this.$t('main.parkingSection.releaseDialog.releaseButton'),
          handler: this.handleRelease,
        },
      });
    },
    handleDismiss() {
      this.isReleasing = true;
      this.$store.dispatch(namespacedCommute.DISMISS_ACTIVE_PARKING);
    },
    async handleRelease() {
      if (!this.reservation) {
        return;
      }
      this.isReleasing = true;

      try {
        await commuteApi.releaseParking(this.reservation.tripId);

        this.$success(this.$t('main.parkingSection.parkingSpotReleased'));
      } catch (error) {
        this.$error(
          error.response?.data?.message ||
            this.$('error.release_parking_failed')
        );
      } finally {
        this.isReleasing = false;
        EventBus.$emit('parkingSpotReleased');
      }
    },
    handleLicensePlateChange(value) {
      this.showSaveButton = value !== this.originalLicensePlate;
    },
    async handleUpdateParkingReservation() {
      if (this.licensePlate.length < 2 || this.licensePlate.length > 8) {
        this.$error(this.$t('main.parkingSection.licensePlateLengthError'));
        return;
      }

      try {
        await commuteApi.updateParkingReservation(this.reservation.tripId, {
          license_plate: this.licensePlate,
        });

        this.$success(this.$t('main.parkingSection.licensePlateUpdated'));
        EventBus.$emit('parkingSpotUpdate');

        this.originalLicensePlate = this.licensePlate;
      } catch (error) {
        this.$error(
          error.response?.data?.message ||
            'Failed to update parking reservation'
        );
      } finally {
        this.showSaveButton = false;
      }
    },
    handleButtonClick() {
      if (this.isExpired) {
        this.handleDismiss();
      } else {
        this.onRelease();
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.parking-header {
  display: flex;
  flex-direction: column;
  gap: 1rem;
  margin-bottom: 15px;
  align-items: center;

  &.parking-header-with-info {
    flex-direction: row;
  }

  .license-plate-text {
    word-wrap: break-word;
    word-break: break-all;
  }
}

.parking-footer {
  margin-top: 15px;
  display: flex;
  flex-direction: column;
  gap: 1rem;
}

.form-section {
  display: flex;
  flex-direction: column;
  gap: 1rem;
}

.parking-details {
  padding: 0;
  margin-bottom: 36px;

  &.parking-details__no-available-spots {
    text-align: center;
  }
}

.detail-section {
  margin-bottom: 1.5rem;

  &:last-child {
    margin-bottom: 0;
  }
}

.parking-expired-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 6px;
}

.spot-number {
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: var(--color-blue-50);
  display: flex;
  justify-content: center;
  height: 52px;
  align-items: center;
  padding: 0 30px;
  border-radius: 6px;
}

.spot-text {
  line-height: 1;
  color: var(--color-grey-darker);
}

.detail-row {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 6px;
}

.detail-column {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 10px;
}

.overview-information-container {
  display: flex;
  flex-direction: column;

  .overview-information-container__title {
    display: flex;
    align-items: center;
    gap: 10px;
    margin-bottom: 15px;
  }

  .overview-information-container__box {
    display: flex;
    flex-direction: row;
    margin-bottom: 8px;

    .overview-information-container__box-time {
      min-width: 40px;
      margin-right: 27px;
    }

    .overview-information-container__box-text {
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }
  }
}

.parking-details-overview {
  width: 100%;
  display: flex;
  justify-content: center;

  .parking-details-overview__inner {
    height: 230px;
    width: 230px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    border-radius: 100%;

    position: relative;
    width: 230px;
    height: 230px;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    transform: rotate(-0deg);
    transform-origin: center;

    .parking-details-overview__inner-content {
      transform: rotate(0deg);
      width: 218px;
      height: 218px;
      background: white;
      border-radius: 50%;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
    }

    .parking-details-overview__inner-section-name {
      font-size: 12px;
      font-weight: 400;
      color: var(--color-blue-900);
      line-height: 22px;
      height: 22px;
      text-transform: uppercase;
    }

    .parking-details-overview__inner-spot-name {
      font-size: 24px;
      font-weight: 400;
      color: var(--color-blue-700);
      min-width: 118px;
      height: 52px;
      display: flex;
      align-items: center;
      justify-content: center;
      padding: 0 25px;
      background-color: var(--color-blue-50);
      margin: 6px 0;
      border-radius: 6px;
    }
  }
}

.parking-overview {
  position: relative;

  &--expired {
    border: 1.5px solid var(--color-neutral-200);
    padding: 24px 16px;
    margin-bottom: 16px;
  }

  &__close-icon {
    position: absolute;
    top: 16px;
    right: 16px;
    cursor: pointer;
    width: 24px;
    height: 24px;
    z-index: 1;
  }
}
</style>
