import axios, { ignoreHandledErrors } from '@/vendor/axios';
import store from '@/store';
import { objectToFormData } from '@/vendor/utils';
import qs from 'qs'
import '@/types';


export default {
  /**
   * @param {Point} origin
   * @param {Point} destination
   * @param {Array<Point>} waypoints
   */
  getRouteBetweenPoints(origin, destination, waypoints = []) {
    return axios
      .get('/route', {
        params: {
          origin_lat: origin.lat,
          origin_lng: origin.lng,
          destination_lat: destination.lat,
          destination_lng: destination.lng,
          waypoints
        },
        paramsSerializer: { serialize: qs.stringify }
      })
      .then((response) => response.data);
  },

  postDriverTrip(data) {
    return axios
      .post('/v3/commute/driver/trip', data)
      .then((response) => response)
  },

  updateDriverTrip(data) {
    return axios
      .post('/v3/commute/driver/trip/update', data)
      .then((response) => response);
  },

  postPassengerRequest(data) {
    return axios
      .post('/v3/commute/passenger/request', data)
      .then((response) => response.data);
  },

  fetchMarketplaceTrip(driverTripId, requestId) {
    return axios
      .get(
        `/v3/commute/marketplace/trip/${driverTripId}?requestId=${requestId}`,
      )
      .then((response) => response.data);
  },

  fetchMarketplaceTripWithStops(driverTripId, departureStopType, departureStopId, destinationStopType, destinationStopId) {
    return axios
      .get(
        `/v3/commute/marketplace/trip/${driverTripId}?departureStopType=${departureStopType}&departureStopId=${departureStopId}&destinationStopType=${destinationStopType}&destinationStopId=${destinationStopId}`
      )
      .then(response => response.data);
  },

  fetchMarketplaceTripWithPivots(driverTripId, departureStopPivotId, destinationStopPivotId) {
    return axios
      .get(
        `/v3/commute/marketplace/trip/${driverTripId}?departureStopPivotId=${departureStopPivotId}&destinationStopPivotId=${destinationStopPivotId}`
      )
      .then((response) => response.data);
  },
  /**
   * @returns {MarketplaceBookTripResource} marketplaceBookTripResource
   */
  marketplaceBook(data) {
    return axios
      .post('/v3/commute/marketplace/book', data, {
        validateStatus: (status) => status < 500,
      })
      .then(response => {
        if (response.data.error) {
          return Promise.reject({ response });
        }

        return response.data;
      });
  },

  cancelTrip(id) {
    return axios
      .get(`/v3/commute/trip/${id}/cancel`)
      .then((response) => response.data);
  },

  cancelRequest(id) {
    return axios
      .get(`/v3/commute/passenger/request/${id}/cancel`)
      .then((response) => response.data);
  },

  cancelBooking(id) {
    return axios
      .get(`/v3/commute/booking/${id}/cancel`)
      .then((response) => response.data);
  },

  denyBooking(id) {
    return axios
      .get(`/v3/commute/booking/${id}/deny`)
      .then((response) => response.data);
  },

  acceptBooking(id) {
    return axios
      .get(`/v3/commute/booking/${id}/accept`)
      .then((response) => response.data)
      .catch(ignoreHandledErrors)
  },

  acceptBookingWithoutIncome(id) {
    return axios
      .get(`/v3/commute/booking/${id}/accept-without-income`)
      .then((response) => response.data);
  },

  fetchRecurringTrip(id) {
    return axios
      .get(`/v3/commute/recurring/${id}`)
      .then((response) => response.data);
  },
  fetchTripSingle(driverTripId) {
    return axios
      .get(`/v3/commute/driver/trip/${driverTripId}`)
      .then((response) => response.data);
  },

  fetchRequestSingle(requestId) {
    return axios
      .get(`/v3/commute/passenger/request/${requestId}`)
      .then((response) => response.data);
  },

  updateRequest(requestId, options) {
    const data = objectToFormData({ _method: 'patch', ...options }, {});
    return axios
      .post(`/v3/commute/passenger/request/${requestId}`, data)
      .then((response) => response.data);
  },

  fetchRecurring() {
    return axios.get('/v3/commute/recurring').then((response) => response.data);
  },

  deleteRecurring(id) {
    return axios
      .delete(`/v3/commute/recurring/${id}`)
      .then((response) => response.data);
  },

  saveRecurring(trip) {
    return axios
      .put(`/v3/commute/recurring/${trip.id}`, trip)
      .then((response) => response.data);
  },

  getOverviewList() {
    return axios
      .get('/v3/commute/overview/list')
      .then((response) => response.data);
  },

  getHistory(page = 1) {
    return axios
      .get(`/commute-history?page=${page}`)
      .then((response) => response.data);
  },

  rateCommuteUser(passengerTripId, rating, comment) {
    return axios
      .post(`/v3/commute/rate/${passengerTripId}`, { rating, comment })
      .then((response) => response.data);
  },

  offerTrip(tripId) {
    return axios
      .patch(`/v3/commute/driver/offer/${tripId}`)
      .then((response) => response.data);
  },

  /** end deprecated */

  getMapItems(bounding, zoom, cancelToken, excludeStops = false,) {
    return axios
      .get('/v5/map-items', {
        params: {
          ...bounding,
          zoom,
          excludeStops: excludeStops ? '1' : '0',
        },
        cancelToken
      })
      .then(response => response.data);
  },

  /**
   * @param {*} options
   * @return EventSource
   */
  marketplaceTripSearch(options) {
    const params = Object.keys(options)
      .map((key) => `&${key}=${options[key]}`)
      .join('');
    const evtSource = new EventSource(
      `${process.env.VUE_APP_ROOT_API_URL}/v4/commute/marketplace/search-stream?token=${store.state.user.token}${params}`,
    );
    evtSource.addEventListener('done', () => {
      evtSource.close();
    }, false);
    return evtSource;
  },

  /**
   * @return EventSource
   */
  tripsFromStop(stopId) {
    const evtSource = new EventSource(
      `${process.env.VUE_APP_ROOT_API_URL}/v4/commute/stop/${stopId}?token=${store.state.user.token}`
    );
    evtSource.addEventListener('done', () => {
      evtSource.close();
    }, false);
    return evtSource;
  },

  /**
   * Create an auto passenger trip
   * @param {Integer} driverTrip The reference trip
   * @param {Integer} fromId from stop id
   * @param {Intger} toId to stop id
   * @returns The newly create auto passenger
   */
  createAutoPassengerRequest(data) {
    return axios
      .post(`v5/auto-passenger`, data)
      .then(response => response.data);
  },

  /**
   * Deletes an auto passenger request
   * @param {Integer} id
   * @returns
   */
  deleteAutoPassengerTrip(id) {
    return axios
      .delete(`v5/auto-passenger/${id}`)
      .then(response => response.data);
  },

  /**
   * Check if a driver trip has an active booking
   */
  driverTripState(driverTripId, timeout = 3000) {
    return axios
      .get(`/driver-trip/${driverTripId}/state`)
      .then(response => response.data);
  },
  async startTrip(driverTripId) {
    await axios
      .post(`commute/${driverTripId}/start`)
      .then((response) => response.data);
  },
  async endTrip(driverTripId) {
    try {
      await axios
        .post(`commute/${driverTripId}/end`)
        .then((response) => response.data);
    } catch (ex) {
      if (ex.handled) {
        return;
      }

      throw ex;
    }
  },
  async getParkingSpot(driverTripId) {
    return axios
      .get(`/commute/${driverTripId}/parking`)
      .then(response => response.data);
  },
  async getActiveParkingReservation() {
    return axios
      .get(`/commute/active-parking`)
      .then(response => response.data);
  },
  async bookParking(driverTripId, data) {
    return axios
      .post(`/commute/${driverTripId}/parking`, data)
      .then(response => response.data);
  },
  async releaseParking(driverTripId) {
    return axios
      .delete(`/commute/release-parking/${driverTripId}`)
      .then(response => response.data);
  },
  async dismissParking(parkingReservationId) {
    return axios
      .delete(`/commute/dismiss-parking/${parkingReservationId}`)
      .then(response => response.data);
  },
  async updateParkingReservation(tripId, data) {
    return axios
      .put(`/commute/${tripId}/parking`, data)
      .then(response => response.data);
  }
};
