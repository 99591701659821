<template>
  <div class="span-6 profile-container">
    <div class="profile-container__image-container">
      <profile-picture
        :src="image"
        size="md"
        class="profile-picture"
      />
    </div>
    <div class="profile-container__information">
      <n-text-model
        v-if="eid_verified"
        size="sm"
        weight="medium"
        color="green-darker"
        class="profile-container__verified-container"
      >
        <n-icon
          name="checkmark_rounded"
          size="sm"
          color="accent"
        />
        {{ $t('profileCard.providerVerified', { provider: $t(`eIDs.${eid_provider}`) }) }}
      </n-text-model>

      <n-text-model
        size="sm"
        weight="medium"
        color="blue-dark"
        class="profile-container__verified-container"
      >
        <n-theme type="driver">
          <n-icon
            name="checkmark_rounded"
            size="sm"
            color="accent"
          />
        </n-theme>
        {{ $t('profileCard.phoneVerified') }}
      </n-text-model>

      <n-text-model
        size="md"
        color="grey-dark"
        class="profile-container__image-container-name"
      >
        <span>
          {{ fullName }} 
        </span>
      </n-text-model>

      <n-text-model
        size="sm"
        weight="medium"
        color="neutral"
      >
        {{ $t('profileCard.balance') }}: {{ $n(balance_available, 'currency', currency) }}
      </n-text-model>

      <n-text-model
        size="sm"
        weight="medium"
        color="neutral"
        class="profile-container__rating-container"
      >
        {{ $t('profileCard.rating') }}:
        {{ ratings_average }}
        <n-icon
          name="account-star"
          size="sm"
          custom-size="18"
          class="star-icon"
          filled
          color="inherit"
        />
      </n-text-model>
    </div>
  </div>
</template>

<script>
import ProfilePicture from '@/components/shared/profilePicture';

export default {
  components: {
    ProfilePicture,
  },
  props: {
    image: {
      type: String,
      default: '',
    },
    name: {
      type: String,
      default: '',
    },
    stats: {
      type: Object,
      default: () => ({
        carpools_completed: 0,
      }),
    },
    // eslint-disable-next-line vue/prop-name-casing
    last_name: {
      type: String,
      default: '',
    },
    // eslint-disable-next-line vue/prop-name-casing
    ratings_average: {
      type: Number,
      default: 0,
    },
    // eslint-disable-next-line vue/prop-name-casing
    eid_verified: {
      type: Boolean,
      default: false,
    },
    // eslint-disable-next-line vue/prop-name-casing
    eid_provider: {
      type: String,
      default: '',
    },
    // eslint-disable-next-line vue/prop-name-casing
    balance_available: {
      type: Number,
      default: 0,
    },
    currency: {
      type: String,
      default: "",
    },
    // temporary prop to hide eid for users without eid featureflag
    hasEidEnabled: {
      type: Boolean,
      default: false,
    }
  },
  computed: {
    fullName() {
      return `${this.name} ${this.last_name}`;
    },
  },
}
</script>

<style lang="scss" scoped>
.profile-container {
  display: flex;
  padding: 20px 31px;
  border-radius: 2px;
  border: 1.5px solid #E3E1DE;
  box-shadow: 0px 3px 4px 0px rgba(0, 0, 0, 0.25);

  .profile-container__image-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    flex-shrink: 1;
    margin-right: 31px;
  }

  .profile-container__image-container-name {
      text-overflow: ellipsis;
      overflow: hidden;
      white-space: nowrap;
      max-width: 100%;
    }

  .profile-container__information {
    flex-shrink: 1;
    display: flex;
    flex-direction: column;
    margin-top: 10px;
    align-self: flex-start;
    overflow: hidden;
    color: var(--color-grey-500);

    .profile-container__verified-container {
      display: flex;
      align-items: center;

      .icon-wrapper {
        margin-right: 8px;
      }
    }

    .profile-container__rating-container {
      display: flex;
      align-items: center;

      .star-icon {
        margin-left:5px;
        fill: #FFD900;
        width: 18px;
        height: 18px;
      }
    }

    span {
      margin-bottom: 5px;
    }
  }
}
</style>