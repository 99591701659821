/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'n_sad-smiley': {
    width: 72,
    height: 71,
    viewBox: '0 0 72 71',
    data: '<circle cx="36" cy="35.5" r="35.5" fill="url(#paint0_linear_1750_3130)"/><circle cx="36" cy="36" r="14.25" stroke="white" stroke-width="1.5"/><circle cx="30.5" cy="32" r="1.5" fill="white"/><circle cx="41.5" cy="32" r="1.5" fill="white"/><path d="M29 43C31.2222 39.9815 37.1333 35.7556 43 43" stroke="white" stroke-width="1.5" stroke-linecap="round"/><defs><linearGradient id="paint0_linear_1750_3130" x1="36" y1="0" x2="50.0247" y2="57.4136" gradientUnits="userSpaceOnUse"><stop stop-color="#00CC6D"/><stop offset="1" stop-color="#85B5FB"/></linearGradient></defs>'
  }
})


