<template>
  <n-column v-if="favourites?.length > 0" :span="6">
    <div class="address-search-favourites">
      <n-scrollable horizontal>
        <div class="item-container">
          <div v-for="(favourite, i) in favourites" :key="i" @click="favourite.onClick" class="item">
            <div class="item--title">
              <n-icon
                class="item--title--icon"
                :name="favourite.icon"
                size="md"
                color="grey-dark"
              />
              <n-text preset="label-2" color="grey-dark" ellipsis>{{
                favourite.label
              }}</n-text>
            </div>
            <n-text preset="sub" color="grey" ellipsis>{{ favourite.text }}</n-text>
          </div>
        </div>
      </n-scrollable>
    </div>
  </n-column>
</template>

<script>
import { mapState } from 'vuex';
import { differenceInSeconds } from 'date-fns';
import {
  namespace as namespaceUser,
  namespacedTypes as namespacedUser,
} from '@/store/modules/user-types';

export default {
  data() {
    return {
      currentAddress: null,
    };
  },
  props: {
    withCurrentLocation: {
      type: Boolean,
      default: true,
    },
  },
  computed: {
    ...mapState(
      namespaceUser,
      ['profile']
    ),
    ...mapState('app', [
      'locations',
      'lastKnownLocationUpdatedAt',
    ]),
    currentLocation() {
      if (!this.locations?.length) {
        return null;
      }

      const locationLifetimeSeconds = differenceInSeconds(
        new Date(),
        new Date(this.lastKnownLocationUpdatedAt)
      );

      if (locationLifetimeSeconds > 300) {
        return null;
      }

      const location = this.locations[this.locations.length - 1];

      return {
        lat: location.lat,
        lng: location.lng,
      };
    },
    favourites() {
      const favourites = [];

      if (this.profile.workplace_pid) {
        favourites.push({
          icon: 'suitcase',
          label: this.$t('c.addressSheet.work'),
          text: this.profile.workplace_name,
          onClick: () => this.$emit('selectWork'),
        });
      }

      if (this.profile.street) {
        favourites.push({
          icon: 'house',
          label: this.$t('c.addressSheet.home'),
          text: this.profile.street,
          onClick: () => this.$emit('selectHome'),
        });
      }

      if (this.withCurrentLocation && this.currentLocation) {
        favourites.push({
          icon: 'position-location-indicator',
          label: this.$t('c.addressSheet.location'),
          text: this.$t('c.addressSheet.current'),
          onClick: () => this.$emit('selectCoordinate', this.currentLocation),
        });
      }

      return favourites;
    },
  },
};
</script>

<style lang="scss" scoped>
@import '@/style/styleguide.scss';

.item-container {
  min-width: 100%;
  padding-left: 0.5rem;
  padding-right: 0.5rem;

  gap: 0.5rem;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
}

.item {
  display: flex;
  flex-direction: column;
  overflow: hidden;
  @extend .feedback;

  max-width: 8rem;

  &--title {
    display: flex;
    flex-direction: row;
    margin-bottom: 0.25rem;

    &--icon {
      margin-right: 0.25rem;
      height: 1.05rem;
      width: 1.05rem;
    }
  }

  .texts {
    display: flex;
    flex-direction: column;
    padding-left: 10px;

    span {
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      width: 110px;
    }
  }
}

.address-search-favourites {
  padding-top: 0.5rem;
  margin-bottom: -1.25rem;
}
</style>
