/* eslint-disable */
var icon = require('vue-svgicon');
icon.register({
  n_carparking: {
    width: 75,
    height: 55,
    viewBox: '0 0 75 55',
    data: '<path d="M1.798 37.096h4.869l-1.298 4.868h-3.57" stroke="#003066" stroke-width="2" stroke-linejoin="round"/><path d="M16.404 35.473h1.622m19.474 0h1.623" stroke="#003066" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/><path d="M12.347 49.954c-4.658 0-10.758.844-10.543-5.625v-6.117c.285-8.368 7.09-.844 11.963-11.18l3.511-5.977c1.791-2.461 3.296-5.414 10.532-4.993h7.45c4.297 0 4.584-.14 8.883 0 5.444 0 8.095 3.657 10.389 8.157 3.114 6.11 4.297 8.438 9.241 8.649 4.723.201 9.957 2.602 9.384 10.618-.266 3.726-.358 7.312-9.671 6.468m-12.192 0H24.226" stroke="#003066" stroke-width="2" stroke-linejoin="round"/><path d="M71.467 37.096H66.71c-.043 1.857 1.196 5.417 6.49 4.796m-38.946-9.663V20.894h-8.32c-2.496-.147-2.799.368-3.63 1.472l-5.9 9.863zm3.246 0V20.894h7.564c2.27-.147 2.544.368 3.3 1.472l5.364 9.863z" stroke="#003066" stroke-width="2" stroke-linejoin="round"/><circle cx="57.785" cy="47.644" r="6.303" stroke="#003066" stroke-width="2"/><circle cx="18.838" cy="47.644" r="6.303" stroke="#003066" stroke-width="2"/><circle cx="57.785" cy="47.645" r="1.434" stroke="#003066" stroke-width="2"/><circle cx="18.838" cy="47.645" r="1.434" stroke="#003066" stroke-width="2"/><path d="m55.55 2.88-6.446 2.43c-1.485.555-2.7 2.312-2.7 3.888v9.597c0 1.524 1.008 3.526 2.235 4.443l5.554 4.146c1.822 1.37 4.818 1.37 6.64 0l5.554-4.146c1.227-.917 2.234-2.92 2.234-4.443V9.197c0-1.59-1.214-3.346-2.7-3.901L59.477 2.88c-1.098-.4-2.854-.4-3.926 0" fill="#0277FF" stroke="#fff" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/><path d="M61.092 13.252q0 .784-.378 1.47-.364.686-1.162 1.106-.784.42-1.988.42h-1.638V20h-1.96v-9.772h3.598q1.134 0 1.932.392.798.391 1.19 1.078.405.685.406 1.554m-3.612 1.414q.812 0 1.204-.364.392-.378.392-1.05 0-1.428-1.596-1.428h-1.554v2.842z" fill="#fff"/>',
  },
});
