<template>
  <div class="missing-information">
    <div class="view-container">
      <n-nav-bar :title="$t('missingInformation.header')" :go-back-override="goBack"/>
      <n-scrollable>
        <n-layout class="layout">
          <n-text preset="body" class="span-6">
            {{ $t('missingInformation.description') }}
          </n-text>

          <n-grid :row-gap="5" :top-gap="3">
            <profile-picture-upload
              v-model="user.image"
              :src="user.existingImage"
              class="span-6"
              size="lg"
              ref="pictureUpload"
            />
            <n-button block @click="$refs.pictureUpload.onClick()">
              {{ $t('missingInformation.profilePictureButton') }}
            </n-button>
            <n-grid :row-gap="4">
              <n-input
                v-model="user.firstName"
                :placeholder="$t('missingInformation.placeholderFirstName')"
                autocomplete="given-name"
              />
              <n-input
                v-model="user.lastName"
                :placeholder="$t('missingInformation.placeholderLastName')"
                autocomplete="family-name"
              />
              <n-input
                v-model="user.email"
                :placeholder="$t('missingInformation.placeholderEmail')"
                autocomplete="email"
              />
            </n-grid>

            <n-grid>
              <n-dropdown
                class="span-3"
                :options="countryOptions"
                v-model="user.country"
                icon="location"
              />
              <n-input
                type="text"
                :placeholder="$t('missingInformation.placeholderZip')"
                v-model="user.zipcode"
              />
            </n-grid>
            <n-grid>
              <n-text class="span-6" preset="sub" color="accent">{{ $t("missingInformation.voucherDescription") }}</n-text>
              <n-input
                v-model="user.voucher"
                :readonly="voucherIsLocked"
                :placeholder="$t('missingInformation.placeholderVoucher')"
              />
            </n-grid>
            <n-grid>
              <n-switch
                v-model="user.newsletter"
                class="span-6"
              >
                {{ $t('missingInformation.newsletterLabel') }}
              </n-switch>
            </n-grid>
            <n-button :loading="isUpdating" @click="update" block size="lg">
              {{ $t('missingInformation.updateButton') }}
            </n-button>
          </n-grid>
        </n-layout>
      </n-scrollable>
    </div>
  </div>
</template>

<script>
import store from '@/store';
import { EventBus } from '@/vendor/events';
import { countryOptions, getCountryOption } from '@/vendor/static-options';
import { namespacedTypes as userTypes } from '@/store/modules/user-types';
import ProfilePictureUpload from '@/components/shared/profilePictureUpload';
import voucherApi from '@/api/voucher';
import utilApi from '@/api/utils';
import { enqueueFormbricksInitEvent } from '@/plugins/Formbricks';

export default {
  components: {
    ProfilePictureUpload,
  },
  data() {
    return {
      isUpdating: false,
      countryOptions,
      user: {
        existingImage: store.state.user.profile.image,
        image: null,
        firstName: store.state.user.profile.name,
        lastName: store.state.user.profile.last_name,
        email: store.state.user.profile.email,
        country: getCountryOption(store.state.user.profile.country),
        zipcode: store.state.user.profile.zipcode,
        newsletter: store.state.user.profile.newsletter_signed_up_at ? true : false,
        voucher: store.state.user.voucher,
      },
    };
  },
  methods: {
    async update() {
      if (this.isUpdating) return;

      const validationError = this.hasValidationError();
      if (validationError) {
        return this.$error(validationError);
      }
      this.isUpdating = true;

      if (this.user.voucher && this.user.voucher.trim()) {
        const isValid = await this.validateVoucher();
        if (!isValid) {
          this.isUpdating = false;
          return;
        }
      }

      const postalCodeValid = await this.validatePostalCode();

      if (!postalCodeValid) {
        this.isUpdating = false;
        return;
      }

      try {
        enqueueFormbricksInitEvent('Action:UserCreated:Success');
        await this.$store.dispatch(userTypes.UPDATE_MISSING_INFORMATION, {
          ...this.user,
          country: this.user.country.id,
        });
        this.$router.resetToHome();
        setTimeout(() => EventBus.$emit('eid-verification-sheet:open'), 500);
      } catch (error) {
        this.handleCreationError(error);
      } finally {
        this.isUpdating = false;
      }
    },
    async validatePostalCode() {
      if (!this.user.zipcode || !this.user.country.id) {
        return;
      }

      const postalCode = this.user.zipcode;
      const countryCode = this.user.country.id;

      try {
        return !!(await utilApi.fetchPostalCode(countryCode, postalCode));
      } catch (error) {
        this.$error(this.$t('missingInformation.invalid.zipcode'));

        return false;
      }

    },
    async validateVoucher() {
      if (!this.user.voucher) {
        return;
      }

      let voucher = null;

      try {
        voucher = await voucherApi.getVoucher(this.user.voucher);
      } catch (error) {
        this.$store.commit('user/SET_VOUCHER', null);
        this.$error(this.$t('voucher.failed'));
      }
      if (
        voucher &&
        ['NEW_USERS_AND_EXISTING_USERS', 'NEW_USERS'].includes(
          voucher.target_group
        )
      ) {
        this.$store.commit('user/SET_VOUCHER', voucher.code);
        this.user.voucher = voucher.code;
        return true;
      } else {
        this.$error(this.$t('voucher.failed'));
      }
      return false;
    },
    hasValidationError() {
      if (!this.user.firstName) {
        return this.$t('missingInformation.invalid.firstName');
      }
      if (!this.user.lastName) {
        return this.$t('missingInformation.invalid.lastName');
      }
      if (!this.user.email) {
        return this.$t('missingInformation.invalid.email');
      }
      if (!this.user.zipcode) {
        return this.$t('missingInformation.invalid.zipcode');
      }
      return false;
    },
    handleCreationError(error) {
      if (error.response?.status === 422 && error.response?.data.errors) {
        const message = Object.entries(error.response.data.errors)
          .reduce((msg, errors) => {
            return `${msg} ${errors[1].join(' ')}`;
          }, '')
          .trim();

        return this.$error(message);
      }

      console.error('handleCreationError', error);
    },

    goBack() {
      store.dispatch(userTypes.SIGN_OUT);
    }
  },
  computed: {
    voucherIsLocked() {
      return !!this.$store.state.user.voucher;
    },
  },
};
</script>
<style scoped>
.missing-information {
  background: var(--color-white) !important;
  position: fixed;
  height: 100vh;
  width: 100vw;
  z-index: 15;
}
</style>
