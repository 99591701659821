<template>
  <GmapMap
    ref="map"
    v-on="$listeners"
    v-bind="$attrs"
    :options="{
      zoomControl: false,
      mapTypeControl: false,
      scaleControl: false,
      streetViewControl: false,
      rotateControl: false,
      fullscreenControl: false,
      disableDefaultUi: true,
      gestureHandling: 'greedy',
      styles: mapsStyle,
      mapTypeId: satellite ? 'satellite' : 'roadmap',
      minZoom: 3,
      restriction: {
        latLngBounds: {
          north: 85,
          south: -85,
          west: -180,
          east: 180
        },
        strictBounds: true
      }
    }"
    :center="{
      lat: 55.70924713420419,
      lng: 9.536194891585472,
    }"
    :zoom="zoom"
    @click="$emit('map-click', $event)"
  >
    <slot />
  </GmapMap>
</template>

<script>
import styleguideMapsStyle from '@/style/styleguide-maps.json';

export default {
  props: {
    satellite: {
      type: Boolean,
      default: false,
    },
    zoom: {
      type: Number,
      default: 11,
    },
  },
  data() {
    return {
    };
  },
  computed: {
    mapsStyle() {
      return styleguideMapsStyle;
    },
  },
  methods: {
    get() {
      return this.$refs.map.$mapPromise;
    },
  },
};
</script>

<style>
.vue-map-container .vue-map {
  position: absolute !important;
}
</style>
