<script>
import Icon from '@/components/core/typography/icon.vue';

export default {
  methods: {
    show(successHandler, cancelHandler) {
      this.$modal.show('dialog', {
          icon: {
            component: Icon,
            props: {
              name: 'car-left-shield',
              color: 'white',
              size: 'xxl',
            },
          },
          title: [
            { 
              text: this.$t('dialog.returnTripDialog.createTitle'),
              color: '#093527',
            },
            {
              text: this.$t('dialog.returnTripDialog.returnRideAlertsTitle'),
              color: '#1A9F74',
            },
          ],
          text: this.$t('dialog.returnTripDialog.description'),
          textPreset: 'sub',
          buttonsInline: true,
          buttonsAlign: 'center',
          success: {
            text: this.$t('dialog.returnTripDialog.createButton'),
            handler: () => successHandler(),
          },
          cancelButton: {
            text: this.$t('dialog.returnTripDialog.laterButton'),
            handler: () => cancelHandler(),
          },
          color: 'success',
          cancel: true,
          closeButton: true,
          clickOutside: false,
        });
    },
  },
  render() {
    return this.$slots.default
  },
}
</script>
