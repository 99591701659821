<template>
  <div class="activity-list span-6">
    <transition-group name="fade" tag="span">
      <component
        v-for="activity in activities"
        :is="activity.component"
        :key="activity.key"
        v-bind="activity.data"
        v-on="activity.events"
      />
    </transition-group>
  </div>
</template>

<script>
import { compareAsc } from 'date-fns';
import { mapState } from 'vuex';
import { commuteBookingStatus } from '@/constants';
import RequestActivity from '@/components/shared/overview/requestActivity';
import DriverTripActivity from '@/components/shared/overview/driverTripActivity';
import PassengerTripActivity from '@/components/shared/overview/passengerTripActivity';

const ACTIVITY_TYPES = {
  REQUEST: 'request',
  PASSENGER_TRIP: 'passenger_trip',
  DRIVER_TRIP: 'driver_trip'
};

const COMPONENT_MAPPING = {
  [ACTIVITY_TYPES.REQUEST]: {
    prefix: 'ra',
    component: 'request-activity',
    getProps: (activity) => ({ request: activity })
  },
  [ACTIVITY_TYPES.PASSENGER_TRIP]: {
    prefix: 'pta',
    component: 'passenger-trip-activity',
    getProps: (activity) => ({ passengerTrip: activity })
  },
  [ACTIVITY_TYPES.DRIVER_TRIP]: {
    prefix: 'dta',
    component: 'driver-trip-activity',
    getProps: (activity) => ({ driverTrip: activity })
  }
};

export default {
  name: 'MainStartOverviewSingle',
  components: {
    RequestActivity,
    DriverTripActivity,
    PassengerTripActivity,
  },
  computed: {
    ...mapState({
      driverTrips: state => state.commute.overview.driverTrips,
      passengerTrips: state => state.commute.overview.passengerTrips,
      requests: state => state.commute.overview.requests,
    }),
    activities() {
      const activities = [
        ...this.requests.map(request => ({
          type: ACTIVITY_TYPES.REQUEST,
          datetime: request.planned_time ?? request.planned_departure,
          activity: request
        })),
        ...this.passengerTrips.map(trip => ({
          type: ACTIVITY_TYPES.PASSENGER_TRIP,
          datetime: trip.planned_departure,
          activity: trip
        })),
        ...this.driverTrips.map(trip => ({
          type: ACTIVITY_TYPES.DRIVER_TRIP,
          datetime: trip.planned_departure,
          activity: trip
        }))
      ];

      const isActiveDriverTrip = (activity) =>
        activity.type === ACTIVITY_TYPES.DRIVER_TRIP &&
        activity.activity.started_at !== null &&
        activity.activity.completed_at === null;

      const sortedActivities = activities.sort((a, b) =>
        Number(isActiveDriverTrip(b)) - Number(isActiveDriverTrip(a)) ||
        compareAsc(new Date(a.datetime), new Date(b.datetime))
      );

      return sortedActivities
        .filter(activity => {
          if (activity.type === ACTIVITY_TYPES.PASSENGER_TRIP && activity.activity.driver_rating) {
            return false;
          }
          if (activity.type === ACTIVITY_TYPES.DRIVER_TRIP && !this.shouldShowDriverTrip(activity.activity)) {
            return false;
          }
          return true;
        })
        .map(activity => {
          const config = COMPONENT_MAPPING[activity.type];
          return {
            key: `${config.prefix}-${activity.activity.id}`,
            component: config.component,
            data: config.getProps(activity.activity)
          };
        });
    },
  },
  methods: {
    shouldShowDriverTrip({ completed_at, passenger_trips }) {
      const isCompleted = completed_at !== null;
      const acceptedPassengers = passenger_trips.filter(p => p.status === commuteBookingStatus.accepted)
      const passengerRated = acceptedPassengers.filter((p) => !!p.passenger_rating)

      if (isCompleted && acceptedPassengers.length == 0) {
        return false
      }

      if (isCompleted && acceptedPassengers.length == passengerRated.length) {
        return false;
      }

      return true;
    },
  }
}
</script>
<style scoped>
.fade-leave-active { transition: opacity 1s; }
.fade-enter, .fade-leave-to { opacity: 0; }
</style>
