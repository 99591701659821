var render = function render(){var _vm=this,_c=_vm._self._c;return _c('GmapMap',_vm._g(_vm._b({ref:"map",attrs:{"options":{
    zoomControl: false,
    mapTypeControl: false,
    scaleControl: false,
    streetViewControl: false,
    rotateControl: false,
    fullscreenControl: false,
    disableDefaultUi: true,
    gestureHandling: 'greedy',
    styles: _vm.mapsStyle,
    mapTypeId: _vm.satellite ? 'satellite' : 'roadmap',
    minZoom: 3,
    restriction: {
      latLngBounds: {
        north: 85,
        south: -85,
        west: -180,
        east: 180
      },
      strictBounds: true
    }
  },"center":{
    lat: 55.70924713420419,
    lng: 9.536194891585472,
  },"zoom":_vm.zoom},on:{"click":function($event){return _vm.$emit('map-click', $event)}}},'GmapMap',_vm.$attrs,false),_vm.$listeners),[_vm._t("default")],2)
}
var staticRenderFns = []

export { render, staticRenderFns }